import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { daysDifference } from '../utils/dateUtils';
import { getLastComment } from '../utils/commentUtils'

const ShipmentTable = ({ shipments, onViewDetails }) => (
  <Table id="shipment-table" striped bordered hover>
    <thead>
      <tr>
        <th>Order & Tracking</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {shipments.map(shipment => (
        <tr key={shipment.tracking_id}>
          <td>
            <div className='order'>
              {shipment.shipment.order_details.order_number}
            </div>
            <div className='payment-type'>
              {shipment.shipment.order_details.order_type}
            </div>
            <div className='tracking'>
              {shipment.shipment.awb_no}
            </div>
            <div className='tracking-link'>
            <a href={`https://healthfab.ithinklogistics.co.in/postship/tracking/${shipment.shipment.awb_no}`} target="_blank">
            {shipment.shipment.logistic}
            </a>
            </div>
            <div>
            {daysDifference(shipment.shipment)} Days
            </div>
          </td>
          <td>
          <div className='shipment-status'>
          {shipment.shipment.current_status}
          </div>
          <div className='shipment-remark'>
          {shipment.shipment.last_scan_details.remark}
          </div>
          <div className='shipment-comment'>
          {getLastComment(shipment.shipment)}
          </div>
          </td>
          <td>
            <Button className='shipment-action' onClick={() => onViewDetails(shipment.tracking_id)}>View</Button>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default ShipmentTable;
