import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ShipmentTable from './components/ShipmentTable';
import ShipmentModal from './components/ShipmentModal';
import ShipmentSummary from './components/ShipmentSummary';
import ShipmentInput from './components/ShipmentInput';
import OTPLogin from './components/OTPlogin';
import { fetchShipments, deleteShipment, fetchShipmentDetails, addComment } from './services/shipmentService';
import { processShipmentsData, fetchAllShipmentDetails } from './utils/shipmentUtils';
import { jsonToCsv, downloadCsv } from './utils/csvUtils'; // Import CSV utils

const App = () => {
  const [shipments, setShipments] = useState([]);
  const [logisticsCount, setLogisticsCount] = useState({});
  const [logisticsValue, setLogisticsValue] = useState({});
  const [totalValue, setTotalValue] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [shipmentDetails, setShipmentDetails] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [shipmentId, setShipmentId] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    const name = localStorage.getItem('userName');
    
    if (token && tokenExpiration && Date.now() < parseInt(tokenExpiration)) {
      setIsAuthenticated(true);
      setUserName(name);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchShipmentsData();
    }
  }, [isAuthenticated]);

  const fetchShipmentsData = async () => {
    setLoading(true);
    try {
      const data = await fetchShipments();
      const {
        updatedShipments,
        newLogisticsCount,
        newLogisticsValue,
        totalValue
      } = processShipmentsData(data);

      setShipments(updatedShipments);
      setLogisticsCount(newLogisticsCount);
      setLogisticsValue(newLogisticsValue);
      setTotalValue(totalValue);
    } catch (error) {
      console.error('Error fetching shipments:', error);
    }
    setLoading(false);
  };

  const handleViewDetails = async (trackingId) => {
    try {
      const data = await fetchShipmentDetails(trackingId);
      setShipmentDetails(data);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching shipment details:', error);
    }
  };

  const handleAddComment = async () => {
    if (newComment.trim()) {
      try {
        const comment = {
          comment_text: newComment,
          comment_date_time: new Date().toISOString(),
          commenter_name: userName,
        };
        await addComment(shipmentDetails.awb_no, comment);
        setNewComment('');
        handleViewDetails(shipmentDetails.awb_no);
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    } else {
      alert('Please enter a comment');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    fetchShipmentsData();
  };

  const handleAuthentication = (status) => {
    setIsAuthenticated(status);
    if (status) {
      setUserName(localStorage.getItem('userName'));
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('userName');
    setIsAuthenticated(false);
    setUserName('');
  };

  const handleExport = async () => {
    try {
      const data = await fetchShipments(); // Fetch shipments
      const csv = jsonToCsv(data); // Convert to CSV
      downloadCsv('shipments.csv', csv); // Trigger download
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  const handleFetchAllDetails = async () => {
    try {
      const data = await fetchShipments(); // Fetch all shipments
      const trackingIds = data.map(shipment => shipment.tracking_id); // Get tracking IDs
      const shipmentDetailsArray = await fetchAllShipmentDetails(trackingIds); // Fetch all shipment details
      console.log(shipmentDetailsArray); // Log the details
    } catch (error) {
      console.error('Error fetching all shipment details:', error);
    }
  };

  return (
    <div className="App">
      <h1>Shipment Issue Management</h1>
      {isAuthenticated ? (
        <>
          <Button onClick={handleLogout}>Logout</Button>
          <div className="container-centered">
            
          </div>
          <div className="container-centered">
            <ShipmentInput
              shipmentId={shipmentId}
              setShipmentId={setShipmentId}
              onFetchDetails={() => handleViewDetails(shipmentId)}
            />
          </div>

          {loading && <p>Loading...</p>}

          <ShipmentSummary
            shipments={shipments}
            logisticsCount={logisticsCount}
            logisticsValue={logisticsValue}
            totalValue={totalValue}
          />
          <div className='Buttons'>
          <Button onClick={handleFetchAllDetails} className="mb-3">Refresh All</Button>

          <Button onClick={handleExport} className="mb-3">Export</Button> {/* Export button */}
          </div>

          <ShipmentTable shipments={shipments} onViewDetails={handleViewDetails} />

          <ShipmentModal
            show={showModal}
            handleClose={handleCloseModal}
            shipmentDetails={shipmentDetails}
            newComment={newComment}
            setNewComment={setNewComment}
            handleAddComment={handleAddComment}
          />
        </>
      ) : (
        <OTPLogin onSuccess={() => handleAuthentication(true)} />
      )}
    </div>
  );
};

export default App;
