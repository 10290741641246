import { formatDate } from '../utils/dateUtils'

export const getLastComment = (shipmentDetails) => {
    const lastComment = shipmentDetails.comments && shipmentDetails.comments.length > 0 
    ? shipmentDetails.comments[shipmentDetails.comments.length - 1]
    : null;
    if (lastComment) {
        return (
            <div className="comment">
             <div className="comment-body">
                {lastComment.comment_text}
              </div>
              <div className="comment-footer">
                <div className="comment-author">{lastComment.commenter_name}</div>
                <div className="comment-time">{formatDate(lastComment.comment_date_time)}</div>
              </div>
            </div>
        );
    }
}