import React from 'react';
import { Form, Button } from 'react-bootstrap';

const ShipmentInput = ({ shipmentId, setShipmentId, onFetchDetails }) => (
  <div className="input-section">
    <Form.Control
      type="text"
      placeholder="Enter shipment ID"
      id='manualShipmentId'
      value={shipmentId}
      onChange={(e) => setShipmentId(e.target.value)}
    />
    <Button onClick={onFetchDetails}>Add New Shipment</Button>
  </div>
);

export default ShipmentInput;
